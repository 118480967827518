<template>
  <div class="titlebg">
    <div class="title_bg">
      <img src="@/assets/image/pc/newsdetails/组 22.png">
      <p v-if="flag" class="title_en">NEWS</p>
      <p v-if="flag" class="title_zh">新闻资讯</p>
    </div>
  </div>
</template>
<script>
export default ({
  name: "TitleBg",
  props: {
    flag: {
      type: Boolean,
      default: true
    }
  }
})
</script>

<style lang="scss" scoped>
.titlebg{
  .title_bg{
    width: 1920px;
    height: 452px;
    img{
      left: 0;
      width: 1920px;
      height: 452px;
      position: absolute;
      background: #2C2B29;
    }
    .title_en{
      top:350px;
      left:838px;
      position: absolute;
      font-size: 80px;
      font-family: Poppins;
      font-weight: 800;
      color: #FFFFFF;
      opacity: 0.76;
      line-height: 104px;
    }
    .title_zh{
      top: 442px;
      left: 889px;
      position: absolute;
      font-size: 35px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #FFFFFF;
    }
  }
}
</style>
