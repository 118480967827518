<template>
  <div class="floor">
    <div class="link">
      <ul>
        <li>
          <a @click="handleClick('/about.html')">关于我们</a>
        </li>
        <li>
          <a @click="handleClick('/cooperation.html')">商务合作</a>
        </li>
        <li>
          <a @click="handleClick('/contact.html')">联系我们</a>
        </li>
        <li>
          <a @click="handleClick('/protocol.html')">用户协议</a>
        </li>
        <li>
          <a @click="handleClick('/privacy.html')">隐私政策</a>
        </li>
        <li>
          <a @click="handleClick('/child.html')">未成年人隐私保护指引</a>
        </li>
        <li>
          <a @click="handleClick('/guardianship.html')">家长监护</a>
        </li>
        <li>
          <a @click="handleClick('/gamesecurity.html')">游戏安全</a>
        </li>
        <li>
          <a @click="handleClick('/thirdpartysdk.html')">第三方SDK</a>
        </li>
      </ul>
    </div>
    <div class="healthygame">
      <p>
        健康游戏忠告：抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防受骗上当
        适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活
      </p>
    </div>
    <div class="record">
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"><p>网站备案号：沪ICP备14010092号-7</p></a>
      <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502014068" target="_blank"><p>沪公网安备 31011502014068号</p></a>
      <p>
        本平台游戏适合18岁以上人群<br>
        沪B2-20140044<br>
        上海域起网络科技有限公司版权所有
      </p>
    </div>
    <div class="linkicon">
      <ul>
        <!-- <li class="cultrue">
          <a
            href="http://sq.ccm.gov.cn/ccnt/sczr/service/business/emark/toDetail/96f03d33ac1a4311bbb134cb72b4045b"
          >
            <img src="@/assets/image/pc/wenhuajingying.png" alt="">
          </a>
        </li> -->
        <li class="gameensure">
          <img src="@/assets/image/pc/youxidzbq.png" alt="">
        </li>
        <li class="trusted">
          <a
            href="https://xyt.xinchacha.com/pcinfo?sn=501547271763136512&certType=6"
          >
            <img src="@/assets/image/pc/可信.png" alt="">
          </a>
        </li>
        <li class="industry">
          <a
            href="https://xinyong.yunaq.com/certificate?domain=www.gg.com&from=label&code=90030"
          >
            <img src="@/assets/image/pc/行业.png" alt="">
          </a>
        </li>
        <li class="wjlogo">
          <a
            @click="handleClick('/resistrumors.html')"
          >
            <img src="@/assets/image/pc/shanghaiwangjingLogoV21.png" alt="">
          </a>
        </li>
      </ul>

    </div>
    <div class="logo">
      <img src="@/assets/image/pc/home/logo白色.png">
    </div>
  </div>
</template>
<script>
export default {
  name: 'Floor',
  data() {
    return {
      titleList: [
        {
          id: 1,
          title: '关于我们',
          path: '/'
        },
        {
          id: 2,
          title: '人才招聘',
          path: '/'
        }
      ]
    }
  },
  created() {

  },
  methods: {
    handleClick(path) {
      this.$router.push({
        path: path
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.floor {
  // position: absolute;
  position: relative;
  width: 100%;
  // height: 252px;
  padding-top: 38px;
  padding-bottom: 100px;
  background: #000;
  .link {
    // position: absolute;
    margin-left: 159px;
    width: 800px;
    ul {
      display: flex;
      justify-content: space-between;
      li {
        a {
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
  .healthygame {
    // position: absolute;
    position: relative;
    top: 12px;
    width: 900px;
    left: 159px;
    p {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #7b7b7b;
      text-align: left;
    }
  }
  .record {
    // position: absolute;
    // margin-top: 93px;
    position: relative;
    margin-left: 159px;
    width: 200px;
    // height: 100px;
    top: 24px;
    a{
      cursor: pointer;
    }
    p {
      cursor: pointer;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #858585;
      // line-height: 16px;
      text-align: left;
      // width: 100%;
    }
  }
  .linkicon {
    position: absolute;
    top: 110px;
    left: 400px;
    width: 750px;
    ul {
      width: 750px;
      display: flex;
      // justify-content: space-between;
      li{
        display: inline-block;
        margin-right: 50px;
      }
      img {
        cursor: pointer;
        width: 100%;
        margin-right: 0.5625rem;
        opacity: 0.76;
        // filter: grayscale(100%);
        // -webkit-filter: grayscale(100%);
        // -moz-filter: grayscale(100%);
        // -ms-filter: grayscale(100%);
        // -o-filter: grayscale(100%);
      }
      // img:hover {
      //   filter: sepia(0%);
      // }
      .cultrue {
        width: 59px;
        height: 60px;
      }
      .gameensure {
        margin-top: 3px;
        width: 47px;
        height: 55px;
      }
      .trusted {
        margin-top: 5px;
        width: 136px;
        height: 50px;
      }
      .industry {
        margin-top: 5px;
        width: 146px;
        height: 50px;
      }
      .wjlogo{
        // margin-left: 10px;
        margin-top: -10px;
        img{
          width: 50px;
        }
      }
    }

  }
  .logo {
    position: absolute;
    // position: relative;
    top: 44px;
    left: 1614px;
    width: 150px;
    height: 150px;
    img {
      width: 146px;
      height: 138px;
      opacity: 0.64;
    }
  }
}
</style>
