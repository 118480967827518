<template>
  <div class="head">
    <div class="box">
      <div class="left">
        <a href="/"><img src="@/assets/image/pc/logo.png" /></a>
      </div>
      <div class="right">
        <ul class="rightUl">
          <li
            v-for="item in titleList"
            :key="item.id"
            @click="handleTitleClick(item)"
          >
            <div>{{ item.title }}</div>
          </li>
          <div class="line"><img src="@/assets/image/pc/竖线.png" /></div>
          <div class="guideDown" @click="isListShow = !isListShow">
            <img src="@/assets/image/pc/导航下拉.png" />
          </div>
        </ul>
        <div
          :id="isListShow && 'animationChangeOver'"
          :class="{ animationChange: !isListShow }"
          class="drop-down"
        >
          <p :class="{ pTop: isListShow }" class="games">GG GAMES</p>
          <ul :class="{ ulTop: isListShow }">
            <li>
              <img src="@/assets/image/pc/home/game1.jpg" alt="" />
              <p>下载游戏</p>
              <div class="linkicon">
                <a :href="mjAndLink"
                  ><img class="an" src="@/assets/image/pc/home/an.png" alt=""
                /></a>
                <a
                  href="https://sdkapi-q.ggbak.com/gameCenter/enjoy?token=zzk4puUOWCH13TXOOb6AuNsd4HCujJUVR%2FWkOzsUWnYup61325VudEzNl2nZRKzIAulLuWVOud86%20EudHyYqX1u1TpkkVZazMIKftC2i86E%3D&wework_cfm_code=MvJKo94z2FMTsRR8Bh1acRYMWgqOkQ5dMlh83l0irYGGGmEVd%2Fn6x3MvRibSlgAubEJAd%2FhifEME54Uzexl0EQ4WlZ%2B9lPxmnUGvaad7e0O9D9F%2BGuF7i3HIfknc9HUEpCXsf3hK4FQEZ1di6nW1waUrp3mP%2FdRVF%2FozN8MmajQ61nyMawOM3agMRKgx6SYFIMLYtzIj2mfIref7LZD370lm6ZvnlcqrpMEX%2B6U8CZkv%2FryFsukJXi8%3D"
                  ><img class="ie" src="@/assets/image/pc/home/ie.png" alt=""
                /></a>
              </div>
            </li>
            <li>
              <img src="@/assets/image/pc/home/2.png" alt="" />
              <p>下载游戏</p>
              <div class="linkicon">
                <a :href="hyAndLink"
                  ><img class="an" src="@/assets/image/pc/home/an.png" alt=""
                /></a>
                <a href="https://apps.apple.com/cn/app/id6504486481"
                  ><img class="ap" src="@/assets/image/pc/home/ip.png" alt=""
                /></a>
              </div>
            </li>
            <li>
              <img src="@/assets/image/pc/home/game3.jpg.png" alt="" />
              <p>下载游戏</p>
              <div class="linkicon">
                <a href="https://thcdn.gggamedownload.com/apk/thlw_1.9.2.apk"><img class="an" src="@/assets/image/pc/home/an.png" alt=""></a>
                <a href="https://apps.apple.com/cn/app/id1575212641"><img class="ap" src="@/assets/image/pc/home/ip.png" alt=""></a>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="isListShow" class="smallflag" />
    </div>
  </div>
</template>
<script>
const axios = require("axios");
export default {
  name: "Head",
  data() {
    return {
      titleList: [
        {
          id: 1,
          title: "关于我们",
          path: "/about.html",
        },
        {
          id: 2,
          title: "人才招聘",
          path: "/career",
        },
      ],
      isListShow: false,
      mjAndLink: "",
      hyAndLink: "",
    };
  },
  created() {},
  methods: {
    handleTitleClick({ id, path, title }) {
      this.$router.push({
        path,
      });
    },
    getMjLink() {
      axios({
        method: "post",
        url: "https://xsdk-cn.yuqinet.com/billing-service/v1/webiste/link/list",
        headers: {
          "App-id": "100007",
          testgg: "testgg",
        },
        data: { link_ids: ["AndroidApk"] },
      }).then((response) => {
        this.mjAndLink = response.data["data"]["list"][0]["link_url"];
      });
    },
    getHyLink() {
      axios({
        method: "get",
        url: "https://lw.yifanghy.com/openApi/links?keywords=gg_com_wdhy%2Flostworld_android%2Flostworld_pc",
      }).then((response) => {
        this.hyAndLink = response.data["data"][0]["data"];
      });
    },
  },
  mounted() {
    this.getMjLink();
    this.getHyLink();
  },
};
</script>

<style lang="scss" scoped>
.head {
  .linkicon {
    width: 60px;
    display: flex;
    justify-content: space-between;
    .an {
      margin-top: 3px;
      width: 24px;
      cursor: pointer;
    }
    .ie {
      margin-top: 3.7px;
      width: 24px;
      cursor: pointer;
    }
    .ap {
      width: 24px;
      cursor: pointer;
    }
  }
  position: relative;
  height: 101px;
  .box {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      align-items: center;
      img {
        cursor: pointer;
        position: absolute;
        top: 23px;
        left: 160px;
        width: 222px;
        height: 61px;
      }
    }
    .smallflag {
      position: absolute;
      left: 1728px;
      top: 88px;
      width: 0;
      height: 0;
      border-bottom: 13px solid #e0e0e0;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
    }
    .right {
      display: flex;
      .rightUl {
        display: flex;
        li {
          margin-left: 110px;
          list-style: none;
          color: #e60012;
          font-size: 27px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          line-height: 38px;
          margin-top: 10px;
          position: relative;
          div {
            cursor: pointer;
          }
        }
        .line {
          margin-left: 89px;
          width: 1px;
          height: 58px;
          img {
            width: 1px;
            height: 58px;
          }
        }
        .guideDown {
          margin-top: 20px;
          margin-left: 84px;
          margin-right: 162px;
          width: 32px;
          height: 18px;
          border-radius: 2px;
          cursor: pointer;
          position: relative;
          img {
            cursor: pointer;
            width: 32px;
            height: 18px;
          }
        }
      }
    }
  }
  .animationChange {
    height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  #animationChangeOver {
    height: 450px;
    transition: height 1s ease !important;
  }
  .drop-down {
    overflow: hidden;
    width: 1920px;
    background-color: #e0e0e0;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 999;
    opacity: 0.95;
    padding: 0 1.375rem;
    padding-bottom: 50px;
    padding-top: 0.3rem;
  }
  .drop-down ul {
    position: relative;
    top: -500px;
    display: flex;
    justify-content: space-evenly;
  }
  .ulTop {
    top: 0 !important;
    transition: top 1s ease !important;
  }
  .pTop {
    top: 0 !important;
    transition: top 1s ease !important;
  }
  .drop-down ul li {
    white-space: nowrap;
    img {
      width: 446px;
    }
  }
  .drop-down .games {
    position: relative;
    font-size: 0.875rem;
    font-size: 27px;
    font-family: Poppins;
    font-weight: 400;
    color: #797979;
    line-height: 73px;
    top: -500px;
  }
  .drop-down ul li p {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #7d7d7d;
    line-height: 28px;
  }
}
</style>
